export const variables = {
  colors: {
    main: "#2B59FF",
    white: "#ffffff",
    lightWhite: "#DDDDDD",
    transparent: "#ffffff0a",
    transparent2: "#ffffff1f",
    transparent3: "#ffffff21",
    transparent4: "#ffffffd5",
    transparent5: "#ffffff05",
    gray: "#999999",
    lightBlue: "#E1E8FD",
    darkBlue: "#000050",
    bgGray: "#F7F7F7",
    dark: "#000",
    lightDark: "#0D070F",
    darkBluePurple: "#230F6E",
  },
  breaks: {
    mobile: 700,
  },
};
