import React, { FC } from 'react'
import { Game, Rockit, GameCardName, GameLines } from "../../features/Home/HomeStyles"
interface IGameCard {
    img: string;
    title: string;
    colorClass?: string;
    rightPosition?: number;
    bgImage: string;
}
const GameCard: FC<IGameCard> = ({ img, title, bgImage, colorClass, rightPosition }) => (
    <Game>
        <div className={bgImage}>
            <Rockit src={img} alt="" />
            <GameCardName style={{ right: rightPosition }}>{title}</GameCardName>
            <GameLines>
                <div className={colorClass} />
                <div className={colorClass} />
            </GameLines>
        </div>
    </Game>
)

export default GameCard