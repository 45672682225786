import { LinkTo, Details, Wrapper, Nav, ScrollLine, Section, EachSection, Heading, MainHeading, Description, Ellipse, HeadingSection, LowerCase, ButtonSpace, GamingSection, Btn, Card, Arrow, Arrows, LeftArrow, RightArrow, ArrowSpace, PartnersArrows, GameLines, News, Line, PartnersSection, Partners, PartnersSlider, GameSection, CertificatesSection, DotsDesignCertificates, GamesText, DotsDesign, RectangleY, RectangleR, EllipseTop, BtnShadow, Games, GamesDivTop, GamesDivBottom, Image, Name, Info, NewsSection, ReadMoreNews, InfoSection, NewsTitle, NewsLink } from './HomeStyles'
import StyledButton from "../../components/Button/Button"
import AboutUsDetail from '../../components/AboutUsDetail/AboutUsDetail'
import security from '../../assets/images/shield-tick.svg'
import promo from '../../assets/images/promo.svg'
import fair from '../../assets/images/fair.svg'
import ellipseHalf from '../../assets/images/ellipse-half.png'
import ellipseTop from '../../assets/images/ellipseTop.png'
import partner from '../../assets/images/partner.svg'
import GameCard from '../../components/GameCard/GameCard'
import rockit from "../../assets/images/rockit.png"
import dots from "../../assets/images/dots.png"
import b88 from "../../assets/images/b88.png"
import arrow from "../../assets/images/arrow.png"
import buttonshadow from "../../assets/images/btnshadow.png"
import leftarrow from "../../assets/images/left-arrow.svg"
import rightarrow from "../../assets/images/right-arrow.svg"
import leftArrowWhite from "../../assets/images/left-arrow-white.svg"
import rightArrowWhite from "../../assets/images/right-arrow-white.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import news from "../../NewsData.json"
import styled from "styled-components";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function Home() {
  const swiper = useSwiper();
  return (
    <Wrapper>
      <div>
        <Nav>
          <NavLink
            to="gaming"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <li></li>
            <ScrollLine></ScrollLine>
          </NavLink>

          <NavLink
            to="aboutus"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <li></li>
            <ScrollLine></ScrollLine>
          </NavLink>

          <NavLink
            to="games"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <li></li>
            <ScrollLine></ScrollLine>
          </NavLink>

          <NavLink
            to="news"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <li></li>
            <ScrollLine></ScrollLine>
          </NavLink>

          <NavLink
            to="partners"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <li></li>
          </NavLink>
        </Nav>
      </div>
      <div>
        <EachSection id='gaming'>
          <GamingSection>
            <HeadingSection style={{ color: "white" }}>
              <MainHeading>MORE THAN <LowerCase>i</LowerCase>GAMING</MainHeading>
              <Description>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Platea bibendum turpis nunc sed sit nulla. Platea bibendum turpis nunc sed sit nulla.</Description>
            </HeadingSection>
            <LinkTo to={"./games"}>
              <StyledButton text="DISCOVER GAMES" c_height={"76px"} c_width={"303px"} marginTop={"47px"} marginBottom={"48px"}></StyledButton>
            </LinkTo>
            <Arrow src={arrow} alt="" />
          </GamingSection>
        </EachSection>
        <EachSection id='aboutus'>
          <Section>
            <Ellipse src={ellipseHalf} alt="" />
            <HeadingSection>
              <Heading>About Us</Heading>
              <Description>Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing better gaming solutions that enables the ultimate entertainment experience. We believe crash games are an essential part of any online casino.</Description>
            </HeadingSection>
            <Details>
              <AboutUsDetail img={security} title="Security" description="Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing." readMore='READ MORE'></AboutUsDetail>
              <AboutUsDetail img={fair} title="Provably Fair" description="Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing." readMore='READ MORE'></AboutUsDetail>
              <AboutUsDetail img={promo} title="Promo tools" description="Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing." readMore='READ MORE'></AboutUsDetail>
            </Details>
          </Section>
        </EachSection>
        <EachSection id='games'>
          <GameSection>
            <EllipseTop src={ellipseTop} alt="" />
            <DotsDesign>
              <img src={dots} alt="" />
              <img src={dots} alt="" />
              <img src={dots} alt="" />
            </DotsDesign>
            <HeadingSection style={{ margin: "35px 0" }}>
              <Heading>Games</Heading>
              <Description>Lots of people play our games daily on different devices. We are proud to be presented in the entertainment industry because we think that playing games is the core of human being.</Description>
            </HeadingSection>
            <Games>
              <GameCard bgImage="rockit" img={rockit} title="rockit" colorClass="red" rightPosition={window.innerWidth > 992 ? 18 : 25} />
              <Line>
                <GamesDivTop>
                  <RectangleR></RectangleR>
                  <GamesText>Rockit</GamesText>
                </GamesDivTop>
                <GamesDivBottom>
                  <GamesText>COMING SOON</GamesText>
                  <RectangleY></RectangleY>
                </GamesDivBottom>
              </Line>
              <GameCard bgImage="sea" img={b88} title="b-88" colorClass="yellow" rightPosition={window.innerWidth > 992 ? 35 : 42}></GameCard>
            </Games>
            <ButtonSpace>
              <LinkTo to={"./games"}>
                <StyledButton text="DISCOVER GAMES" c_height={"76px"} c_width={"100%"} marginTop={"41px"} marginBottom={"60px"}></StyledButton>
              </LinkTo>
              <BtnShadow src={buttonshadow} alt="" />
            </ButtonSpace>
          </GameSection>
        </EachSection>
        <EachSection id='news'>
          <NewsSection>
            <Heading>latest news</Heading>
            <News>
              <Swiper
                breakpoints={{
                  280: {
                    // width: 380,
                    slidesPerView: 1,
                  },
                  640: {
                    spaceBetween: 20,
                    slidesPerView: 2,
                  },

                  980: {
                    spaceBetween: 50,
                    slidesPerView: 3,
                  }
                }}
                modules={[Navigation, Scrollbar]}
                spaceBetween={50}
                slidesPerView={3}
                navigation={{ nextEl: "#swiper-forward", prevEl: "#swiper-back" }}
              >
                {
                  news.map((data, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <NewsLink to={`/news/${data.title}`}>
                          <Card>
                            <div>
                              <Image src={`/${data.img}`} alt="" />
                            </div>
                            <InfoSection>
                              <NewsTitle>
                                <Name>{data.title}</Name>
                              </NewsTitle>
                              <Info>{data.description}</Info>
                            </InfoSection>
                          </Card>
                        </NewsLink>
                      </SwiperSlide>
                    );
                  })
                }
              </Swiper>
            </News>
            <Arrows>
              <ArrowSpace id="swiper-back">
                <LeftArrow src={leftarrow} alt="" />
              </ArrowSpace>
              <ArrowSpace id="swiper-forward">
                <RightArrow src={leftarrow} alt="" />
              </ArrowSpace>
            </Arrows>
          </NewsSection >
        </EachSection>
        <EachSection id='partners'>
          <PartnersSection>
            <Heading style={{ color: "white", marginBottom: 0, marginTop: 0 }}>Partners</Heading>
            <Partners>
              <Swiper
                breakpoints={{
                  280: {
                    width: 350,
                    slidesPerView: 1,
                  },
                  662: {
                    width: 662,
                    slidesPerView: 2,
                  },
                }}
                modules={[Navigation, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={3}
                navigation={{ nextEl: "#swipe-forward", prevEl: "#swipe-back" }}
              >
                <SwiperSlide> <PartnersSlider><img src={partner} alt="" /></PartnersSlider></SwiperSlide>
                <SwiperSlide> <PartnersSlider><img src={partner} alt="" /></PartnersSlider></SwiperSlide>
                <SwiperSlide> <PartnersSlider><img src={partner} alt="" /></PartnersSlider></SwiperSlide>
                <SwiperSlide> <PartnersSlider><img src={partner} alt="" /></PartnersSlider></SwiperSlide>
                ...
              </Swiper>
            </Partners>
            <PartnersArrows>
              <img src={leftArrowWhite} alt="" id="swipe-back" />
              <img src={rightArrowWhite} alt="" id="swipe-forward" />
            </PartnersArrows>
          </PartnersSection>
          <CertificatesSection>
            <HeadingSection>
              <Heading>certificates</Heading>
              <Description>Playtron owns various certifications for each game to prove our products<br></br>
                operate legally on different markets and guarantee a safe environment for players.</Description>
            </HeadingSection>
            <DotsDesignCertificates>
              <img src={dots} alt="" />
              <img src={dots} alt="" />
            </DotsDesignCertificates>
          </CertificatesSection>
        </EachSection>
      </div >
    </Wrapper>
  )
}

const NavLink = styled(Link)`
  &.active li {
    color: #1890ff;
    background-color: #2B59FF;
    transform: scale(1.3);
    border: none;
    opacity: 100%;
  }
`;


export default Home
