import React, { FC } from 'react'
import { CardWrapper, Card, CardTitle, CardDescription } from "../../features/AboutUs/AboutUsStyles"
interface ITimelineCard {
    title: string;
    description: string;
}

const TimelineCard: FC<ITimelineCard> = ({ title, description }) => (
    <CardWrapper>
        <Card>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
        </Card>
    </CardWrapper>
)


export default TimelineCard