import { FC } from "react";
import { IconProps } from "./types";

export const Linkedin: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23 0C10.318 0 0 10.317 0 23C0 35.683 10.318 46 23 46C35.682 46 46 35.683 46 23C46 10.317 35.682 0 23 0ZM16 33H12V18H16V33ZM14 15C12.895 15 12 14.105 12 13C12 11.895 12.895 11 14 11C15.105 11 16 11.895 16 13C16 14.105 15.105 15 14 15ZM35 33H31V28V25.5C31 23.575 29.425 22 27.5 22C25.575 22 24 23.575 24 25.5V33H20V18H24V19.816C25.168 18.694 26.752 18 28.5 18C32.09 18 35 20.91 35 24.5V33Z"
        fill="#230F6E"
      />
    </svg>
  );
};

export default Linkedin;
