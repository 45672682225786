import styled from "styled-components";
import FacebookIcon from "../../components/Icons/Facebook";
import LinkedinIcon from "../../components/Icons/Linkedin";

import { NavLink as Link } from "react-router-dom";

export const StyledHeader = styled.div`
  background: ${(props) => props.theme.colors.lightDark};
  background: transparent;
  // position: absolute;
  width: 100%;
  padding: 0 5%;
  z-index: 5;

  @media (min-width: ${1000}px) {
    background: transparent;
  }

  @media (max-width: 992px) {
    position: fixed;
    top: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  @media (max-width: 992px) {
    padding: 17px 0;
  }
`;

export const Logo = styled.img`
  // height: 55px;
  width: 215px;

  @media (min-width: ${1000}px) {
    height: auto;
  }

  @media (max-width: 992px) {
    height: auto;
    width: 127px;
  }
`;

export const Navigation = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 1%;
  right: -100%;
  margin-top: 59px;
  padding: 0;
  width: 100%;
  // width: auto;
  // justify-content: center;
  // align-items: flex-start;
  list-style-type: none;
  background: ${(props) => props.theme.colors.lightDark};
  transition: all 0.7s;
  z-index: 9;
  padding: 0 10px;
  height: 100%;

  &.open {
    // top: 0;
    // left: 0;
    right: 0;
  }


  @media (min-width: ${1000}px) {
    position: inherit;
    flex-direction: row;
    margin: 0;
    background: transparent;
    width: auto;
  }
`;
export const NavLogo = styled(Link)`

`
export const NavLink = styled(Link)`
  &.active {
    border-bottom: 2px solid #2B59FF;
    color: #2B59FF !important;
  }
`;

export const NavItem = styled.li`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  margin-bottom: 10px;

  a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }

  @media (min-width: ${1000}px) {
    margin-left: 55px;
    overflow: hidden;
    margin-bottom: 0;
  }

  @media (max-width: 992px) {
    padding: 14px 24px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const SocialMedia = styled.div`
display: none;

@media (max-width: 992px) {
  display: block;
  filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(29deg) brightness(107%) contrast(101%);
  width: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  margin: 24px 0;
}
`

export const SocialMediaLink = styled.a`

`

export const Facebook = styled(FacebookIcon)`
width: 32px;
cursor: pointer;
`

export const Linkedin = styled(LinkedinIcon)`
width: 32px;
cursor: pointer;
`
