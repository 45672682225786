import styled from "styled-components";

export const Container = styled.div`
background: linear-gradient(180deg, #F7F7F7 34.51%, #FFFFFF 71.27%);
@media screen and (max-width: 576px) { 
    margin-top: 15%;
}

@media (min-width: 577px) and (max-width: 992px) { 
    margin-top: 8%;
}
`
export const Wrapper = styled.div`
width: 90%;
margin: auto;
background: #FFFFFF;
box-shadow: 0px 11px 12px 3px rgba(0, 0, 0, 0.04);
border-radius: 22px;
margin-top: 65px;
margin-bottom: 112px;
display: flex;
flex-direction: column;
align-items: center;

@media screen and (max-width: 992px) { 
    margin-top: 24px;
    margin-bottom: 54px;
}
`

export const Title = styled.h2`
font-size: 22px;
line-height: 22px;
text-align: center;
`

export const Image = styled.img`
max-width: 753px;
max-height: 409px;
background: #0D1425;
border-radius: 22px;
padding: 3% 10%;
margin-top: 54px;
margin-bottom: 32px;

@media screen and (max-width: 992px) { 
    margin-top: 24px;
    margin-bottom: 16px;
}
`

export const Description = styled.div`
width: 76%;
margin-top: 24px;
margin-bottom: 56px;
color: #999999;
font-size: 18px;
line-height: 21px;

@media screen and (max-width: 992px) { 
    width: 86%;
    margin-top: 8px;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 16px;

}
`
