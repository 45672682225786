import React from 'react'
import GameDetailCard from '../../components/GameDetailCard/GameDetailCard'
import RockitGame from "../../../src/assets/images/rockit-game.svg"
import mobile from '../../assets/images/mobile.svg'
import gameImg from '../../assets/images/game-img.png'
import freebet from '../../assets/images/freebet.png'
import promo from '../../assets/images/promo.svg'
import fair from '../../assets/images/fair.svg'
import AboutUsDetail from '../../components/AboutUsDetail/AboutUsDetail'
import statistics from "../../assets/images/statistics.svg"
import live from "../../assets/images/live.svg"
import currency from "../../assets/images/multi-currency.svg"
import circles from "../../assets/images/circles.svg"
import rockitMan from "../../assets/images/rockit-man.svg"
import dots from "../../assets/images/dots.png"
import StyledButton from "../../components/Button/Button"
import buttonshadow from "../../assets/images/btnshadow.png"
import ContactUs from '../../components/ContactUs/ContactUs'

import { Wrapper, ButtonSpace, BtnShadow, SectionBackground, GameSection, GameSectionInfo, DevicesSection, DevicesSectionDots, Title, Description, Image, InfoImage, Details, Section, GameDetailsSection, GameImage, FreeBetsSection, FreeBetsSectionTitle, FreeBets, FreebetsImage, FreeBetsTitle, FreeBetsDescription, FreebetsContent } from "./GamesStyles"

function Games() {
    return (
        <Wrapper>
            <SectionBackground></SectionBackground>
            {/* <ButtonSpace>
                <StyledButton text={window.innerWidth > 992 ? "PLAY FOR FUN" : "DISCOVER GAMES"} c_height={"76px"} c_width={"100%"}></StyledButton>
                <BtnShadow src={buttonshadow} alt="" />
            </ButtonSpace> */}
            <GameSection>
                <GameSectionInfo>
                    <Title>ROCKIT</Title>
                    <Description>
                        Rockit is a new kind of social multiplayer game consisting of an increasing curve that can crash anytime. When round starts, a scale of multiplier starts growing. Player must cash out before lucky plane flies away. <br></br>
                        Originated from video gaming industry, this format became popular in crypto casinos, due to its simplicity and exciting gambling experience.

                        We are amongst pioneers to introduce this game to regular online casinos.
                    </Description>
                </GameSectionInfo>
                <GameImage>
                    <InfoImage src={RockitGame} alt="" />
                </GameImage>
            </GameSection>
            <DevicesSection>
                <DevicesSectionDots>
                    <img src={dots} alt="" />
                    <img src={dots} alt="" />
                    <img src={dots} alt="" />
                </DevicesSectionDots>
                <Image src={gameImg} alt="" />
            </DevicesSection>
            {/* <ButtonSpace>
                <StyledButton text={window.innerWidth > 992 ? "PLAY DEMO" : "DISCOVER GAMES"} c_height={"76px"} c_width={"100%"}></StyledButton>
                <BtnShadow src={buttonshadow} alt="" />
            </ButtonSpace> */}
            <Section>
                <Details>
                    <AboutUsDetail img={mobile} title="Mobile first" description="Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing."></AboutUsDetail>
                    <AboutUsDetail img={fair} title="Provably Fair" description="Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing."></AboutUsDetail>
                    <AboutUsDetail img={promo} title="Light" description="Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing."></AboutUsDetail>
                </Details>
            </Section>
            <FreeBetsSection>
                <FreeBetsSectionTitle>Promo freebets</FreeBetsSectionTitle>
                <FreebetsContent>
                    <FreebetsImage src={freebet} alt="" />
                    <FreeBets>
                        <FreeBetsTitle>freebets</FreeBetsTitle>
                        <FreeBetsDescription>Free bets are the best way to introduce game to your customers or just use as a gift in random promotions and giveaways.</FreeBetsDescription>
                    </FreeBets>
                </FreebetsContent>
            </FreeBetsSection>
            <GameDetailsSection>
                <GameDetailCard title='Live statistics' description='Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing.' icon={statistics} colorClass="blue"></GameDetailCard>
                <GameDetailCard title='Live lederports' description='Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing.' icon={live} colorClass="red"></GameDetailCard>
                <GameDetailCard title='Multi currency bets' description='Playtron offers next level iGaming solutions to the world’s most successful online casino operators. We are passionate about developing.' icon={currency} colorClass="green"></GameDetailCard>
            </GameDetailsSection>
            <ContactUs></ContactUs>
        </Wrapper>
    )
}
export default Games