import { FC } from "react";
import { IconProps } from "./types";

export const Facebook: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22 0C9.85 0 0 9.85 0 22C0 33.03 8.125 42.137 18.712 43.728V27.831H13.269V22.048H18.712V18.2C18.712 11.829 21.816 9.032 27.111 9.032C29.647 9.032 30.988 9.22 31.623 9.306V14.354H28.011C25.763 14.354 24.978 16.485 24.978 18.887V22.048H31.566L30.672 27.831H24.978V43.775C35.716 42.318 44 33.137 44 22C44 9.85 34.15 0 22 0Z"
        fill="#230F6E"
      />
    </svg>
  );
};

export default Facebook;
