import { Route, Routes } from "react-router-dom";
import Home from "./features/Home";
import Games from "./features/Games/Games";
import AboutUs from "./features/AboutUs/AboutUs";
import News from "./features/News/News";
import NewsFullCard from "./features/News/NewsFullCard/NewsFullCard";
import Contact from "./features/Contact/Contact";
import MainLayout from "./layout/MainLayout";
import TermsConditions from "./features/HelpfullLinks/Terms/TermsConditions"
import PrivacyPolicy from "./features/HelpfullLinks/Policy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/news" element={<News />}></Route>
        <Route path="/news/:title" element={<NewsFullCard />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/termsandconditions" element={<TermsConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Route>
    </Routes>
  );
}

export default App;
