import React, { FC } from 'react'
import { Card, GameCardDetail, GameCardDetailTitle, GameCardDetailDescription, GameIcon, GameIconSpace } from "../../features/Games/GamesStyles"

interface IGameDetailCard {
    title: string;
    description: string;
    icon: string;
    colorClass?: string;
}

const GameDetailCard: FC<IGameDetailCard> = ({ title, description, icon, colorClass }) => {
    return <Card>
        <GameCardDetail>
            <GameCardDetailTitle>{title}</GameCardDetailTitle>
            <GameCardDetailDescription>{description}</GameCardDetailDescription>
            <GameIconSpace>
                <div className={colorClass}>
                    <GameIcon src={icon} alt="" />
                </div>
            </GameIconSpace>
        </GameCardDetail>
    </Card>;
}

export default GameDetailCard
