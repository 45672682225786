import styled from "styled-components";

export const MainFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 29px;
  padding-left: 15px;

  @media (min-width: ${1000}px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 48px 80px 25px 80px;
  }
`;

export const About = styled.div`
  margin-bottom: 17px;

  @media (min-width: ${1000}px) {
    order: 1;
    margin-bottom: 0;
  }
`;

export const Logo = styled.img`
  width: 205px;
  margin-bottom: 16px;

  @media (min-width: ${1000}px) {
    height: 52px;
    margin-bottom: 51px;
  }

  @media (max-width: 992px) {
   width: 127px;
  }
`;

export const MainText = styled.div`
  font-size: 16px;
  line-height: 18px;
  max-width: 258px;

  @media (min-width: ${1000}px) {
    font-size: 22px;
    line-height: 25px;
    max-width: 356px;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  margin-bottom: 25px;

  a:nth-child(2) {
    margin-left: 27px;
  }

  @media (min-width: ${1000}px) {
    order: 3;
    margin-bottom: 0;
  }
`;

export const SocialMediaLink = styled.a`

`
export const FooterNav = styled.div`
  display: flex;

  @media (min-width: ${1000}px) {
    order: 2;
  }
`;

export const Nav = styled.ul`
  &:nth-child(2) {
    margin-left: 80px;
  }

  @media (min-width: ${1000}px) {
    &:nth-child(2) {
      margin-left: 250px;
    }
  }
`;

export const NavItem = styled.li`
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 25px;
  font-weight: 400;

  &.title {
    color: ${(props) => props.theme.colors.darkBluePurple};
    font-weight: 500;
  }

  a {
    text-decoration: none;
    color: #444444;
  }

  @media (min-width: ${1000}px) {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 25px;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }
`;

export const CopyRight = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px 0;
  border-top: 1px solid #E8E8E8;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;

  @media (min-width: ${1000}px) {
    font-size: 12px;
    line-height: 14px;
    padding: 24px 0;
  }
`;
