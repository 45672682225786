import { useState } from "react";
import {
  StyledHeader,
  HeaderWrapper,
  Logo,
  Navigation,
  NavItem,
  SocialMedia,
  SocialMediaLink,
  Facebook,
  Linkedin,
  NavLink,
  NavLogo
} from "./HeaderStyle";
import logo from "../../assets/images/logo.png";
import logoBlue from "../../assets/images/logo-blue.svg"
import Burger from "../../components/BurgerNav/Burger";
import { useLocation } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }
  return (
    <StyledHeader style={{
      background: useLocation().pathname !== "/" && window.innerWidth > 992 ? "white" : "#0D070F",
      boxShadow: useLocation().pathname !== "/" && window.innerWidth > 992 ? ' 0px 4px 10px rgba(0, 0, 0, 0.09)' : "none"
    }}>
      <div className="container">
        <HeaderWrapper >
          <NavLogo to="/" onClick={() => refreshPage()}>
            <Logo src={useLocation().pathname !== "/" && window.innerWidth > 992 ? logoBlue : logo} alt="logo" />
          </NavLogo>
          <Burger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
          <Navigation className={isOpen ? "open" : "closed"} style={{
            color: useLocation().pathname !== "/" ? "#444444" : "#FFFFFF"
          }}>
            <NavItem>
              <NavLink to="/" onClick={() => setIsOpen(!isOpen)} style={{
                color: useLocation().pathname !== "/" && window.innerWidth > 992 ? "#444444" : "#FFFFFF"
              }}>Home</NavLink>
            </NavItem>

            <NavItem className="active">
              <NavLink to="/games" onClick={() => setIsOpen(!isOpen)} style={{
                color: useLocation().pathname !== "/" && window.innerWidth > 992 ? "#444444" : "#FFFFFF"
              }}>Games</NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/aboutus" onClick={() => setIsOpen(!isOpen)} style={{
                color: useLocation().pathname !== "/" && window.innerWidth > 992 ? "#444444" : "#FFFFFF",
              }}>About Us</NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/news" onClick={() => setIsOpen(!isOpen)} style={{
                color: useLocation().pathname !== "/" && window.innerWidth > 992 ? "#444444" : "#FFFFFF"
              }}>News</NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/contact" onClick={() => setIsOpen(!isOpen)} style={{
                color: useLocation().pathname !== "/" && window.innerWidth > 992 ? "#444444" : "#FFFFFF"
              }}>Contact</NavLink>
            </NavItem>

            <SocialMedia>
              <SocialMediaLink href="https://www.linkedin.com/company/playtron/">
                <Linkedin></Linkedin>
              </SocialMediaLink>
              <Facebook></Facebook>
            </SocialMedia>
          </Navigation>
        </HeaderWrapper>
      </div>
    </StyledHeader>
  );
}

export default Header;
