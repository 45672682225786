import {
  MainFooter,
  About,
  Logo,
  MainText,
  SocialMedia,
  SocialMediaLink,
  FooterNav,
  Nav,
  NavItem,
  CopyRight,
} from "./FooterStyle";
import logo from "../../assets/images/footer-logo.png";
import Linkedin from "../../components/Icons/Linkedin";
import Facebook from "../../components/Icons/Facebook";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <MainFooter className="container">
        <About>
          <Link to="/">
            <Logo src={logo} alt="logo" />
          </Link>

          <MainText>
            Playtron offers next level iGaming solutions to the world’s most
            successful online casino operators.
          </MainText>
        </About>

        <SocialMedia>
          <SocialMediaLink href="https://www.linkedin.com/company/playtron/">
            <Linkedin />
          </SocialMediaLink>

          <Link to="">
            <Facebook />
          </Link>
        </SocialMedia>

        <FooterNav>
          <Nav>
            <NavItem className="title">COMPANY</NavItem>

            <NavItem>
              <Link to="/aboutus">About Us</Link>
            </NavItem>

            <NavItem>
              <Link to="/games">Games</Link>
            </NavItem>

            <NavItem>
              <Link to="/news">News</Link>
            </NavItem>
          </Nav>

          <Nav>
            <NavItem className="title">HELPFUL LINKS</NavItem>

            <NavItem>
              <Link to="/termsandconditions">Terms & conditions</Link>
            </NavItem>

            <NavItem>
              <Link to="/privacypolicy">Privacy policy</Link>
            </NavItem>
          </Nav>
        </FooterNav>
      </MainFooter>

      <CopyRight>
        <div>Copyright © 2021 Playtron. All rights reserved.</div>
      </CopyRight>
    </footer>
  );
}

export default Footer;
