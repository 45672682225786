import styled from "styled-components";
import bg from "../../assets/images/game_bg.png"
import bgSea from "../../assets/images/game-bg2.png"
import { Link } from "react-router-dom"
import Shadow from "../../assets/images/shadow.png"

export const SliderWrapper = styled.div`

`

export const Wrapper = styled.div`
@media screen and (max-width: 576px) { 
  margin-top: 15%;
}

@media (min-width: 577px) and (max-width: 992px) { 
  margin-top: 8%;
}

`
export const Section = styled.div`
padding: 0 10%;

@media screen and (max-width: 992px) { 
  padding: 0 5%;
  }

  @media screen and (min-width: 992px) { 
    padding: 2% 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
  }

  @media screen and (min-width: 1200px) { 
    padding: 2% 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
  }
`
export const Nav = styled.nav`
margin: 0;
padding: 0;
position: fixed;
right: 57px;
list-style: none;
top: 50%;
transform: translateY(-50%);
z-index: 11;

  li {
    width: 12px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    opacity: 50%;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
      opacity: 100%;
    }
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;
export const ScrollLine = styled.div`
width: 1px;
height:30px;
background-color: #000000;
opacity: 0.2;
margin: auto;
z-index: 10;
`
export const EachSection = styled.div`
@media screen and (min-width: 992px) { 
  height: 100vh;
}
`
export const LinkTo = styled(Link)``

export const GamingSection = styled.div`
display: flex;
flex-direction:column;
align-items: center;
padding: 0 10%;
background: linear-gradient(180deg, #0D070F 0%, #1F102B 100%);;
text-align: center;
height: auto;

@media screen and (max-width: 992px) { 
  padding: 0;
  height: auto;
  }

  @media screen and (min-width: 992px) { 
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 1200px) { 
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
export const Ellipse = styled.img`
position: absolute;
right: 0;

@media screen and (max-width: 992px) { 
 display: none;
 }
`

export const Arrow = styled.img`
margin-top: 108px;
margin-bottom: 50px;

@media screen and (max-width: 992px) { 
  width: 14px;
  margin-top: 53px;
  margin-bottom: 50px;
  }
`
export const HeadingSection = styled.div`
padding: 35px 10% 50px 10%;

@media screen and (max-width: 992px) { 
  padding: 24px 10%;
  }

  @media screen and (min-width: 992px) { 
    padding: 0;
  }
`
export const Heading = styled.h2`
text-align: center;
font-style: normal;
font-weight: 500;
font-size: 42px;
letter-spacing: 0.14em;
text-transform: uppercase;
margin: 30px 0;

@media screen and (max-width: 992px) { 
  font-size: 24px;
  }

  @media screen and (min-width: 992px) { 
    margin: 0;
  }
`

export const MainHeading = styled.h3`
text-align: center;
font-style: normal;
font-weight: 500;
font-size: 62px;
text-transform: uppercase;
margin: 80px 0 80px 0;

@media screen and (max-width: 992px) { 
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 45px;
  }
`
export const Details = styled.div`
 display: flex;
 gap: 120px;
 justify-content: space-evenly;
 text-align: center;

 @media screen and (max-width: 992px) { 
  display: flex;
  flex-direction: column;
  gap: 50px;
  }
`
export const DetailsWrapper = styled.div`
text-align: center;
position: relative;
`
export const Icon = styled.img`
width: 100%;
height: 100%;
position: relative;
background-color: #E1E8FD;
border-radius: 50%;
padding: 30px;
z-index: 2;

@media screen and (max-width: 992px) { 
  padding: 16px;
}
`
export const IconSpace = styled.div`
width: 122px;
position: relative;
margin: auto;

@media screen and (max-width: 992px) { 
  width: 60px;
  height: 60px;
}
`
export const IconShadow = styled.img`
position: absolute;
top: -60%;
left: -60%;
z-index: 1;

@media screen and (max-width: 992px) { 
  top: -75%;
  left: -75%;
  width: 150px;
  height: 150px;
}
`

export const Title = styled.h2`
font-weight: 400;
font-size: 28px;
margin: 25px;

@media screen and (max-width: 992px) { 
  font-size: 16px;
  margin: 14px;
}
`
export const Description = styled.p`
font-size: 14px;
text-align: center;
color: #999999;
padding: 0 20%;

@media screen and (max-width: 992px) { 
  padding: 0;
}
`
export const ReadMore = styled(Link)`
font-weight: 400;
font-size: 14px;
font-style: normal;
line-height: 16px;
text-align: center;
text-transform: uppercase;
color: #2B59FF;
position: relative;
text-decoration: none;
padding-bottom: 5px;

::after{
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    bottom: 0;
    left: 10%;
    border-bottom: 1px solid #000050;
  }

  @media screen and (max-width: 992px) { 
    font-size: 12px;
   }
`

export const DotsDesign = styled.div`
img:nth-child(1) {
  position: absolute;
  right: 0;
  top: 25%;

  @media screen and (max-width: 992px) { 
     width: 110px;
     top: 1%;
     right: 0;
    }
}

img:nth-child(2) {
  position: absolute;
  left: 30%;
  top: 45%;

  @media screen and (max-width: 992px) { 
    width: 100px;
    top: 50%;
    left: 0;
   }
}

img:nth-child(3) {
  position: absolute;
  left: 2%;
  bottom: 15%;

  @media screen and (max-width: 992px) { 
    width: 110px;
    bottom: 1%;
    left: 70%;
   }
}
`
export const GameSection = styled.div`
background-color: #F7F7F7;
padding: 0 10%;
position: relative;

@media screen and (max-width: 1280px) {
  padding: 0 2%;
  padding-bottom: 39px;
}

@media screen and (min-width: 992px) { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0;
}

@media screen and (min-width: 1200px) { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  height: 100%;
}

`
export const Games = styled.div`
display: flex;
justify-content: space-between;
margin: 0 auto;
height: 800px;
width: 90%;

div:nth-child(2) {
  align-self: center;

  @media screen and (min-width: 992px) { 
    align-self: flex-start;
  }
  
  @media screen and (min-width: 1200px) { 
    align-self: flex-start;
  }
}

div:nth-child(3) {
  align-self: flex-end;
}

@media screen and (max-width: 992px) { 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 50px;
  padding-bottom: 52px;

  div:nth-child(1) {
    align-self: center;
    max-width: 350px;
    max-height: 330px;
  }
  
  div:nth-child(3) {
    align-self: center;
  }
}

@media screen and (min-width: 992px) { 
  width: 80%;
  height: 100%;
}

`

export const Game = styled.div`
div.rockit {
  background-image: url(${bg});
  width: 400px;
  height: 385px;
  border-radius: 20px;
  border: none;
  position: relative;

  @media screen and (min-width: 992px) { 
    width: 280px;
    height: 280px;
  }
}

div.sea {
  border: 1px solid red;
  width: 400px;
  height: 385px;
  border-radius: 20px;
  border: none;
  position: relative;
  background-image: url(${bgSea});
  background-image: no repeat;

  @media screen and (min-width: 992px) { 
    width: 280px;
    height: 280px;
  }
}
`

export const GamesDivTop = styled.div`
display: flex;
position: absolute;
align-items: center;
gap: 50px;
left: -9px;
top: 10%;
`

export const GamesDivBottom = styled.div`
display: flex;
position: absolute;
align-items: center;
right: -8px;
gap: 50px;
bottom: 10%;
white-space: nowrap;
`
export const GamesText = styled.h3`
font-style: normal;
font-weight: 600;
font-size: 28px;
text-transform: uppercase;
letter-spacing: 0.14em;
`

export const GameCardName = styled.h2`
position: absolute;
color: white;
bottom: 8%;
right: 9%;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 49px;
text-transform: uppercase;
letter-spacing: 0.14em;

@media screen and (max-width: 992px) { 
  right: 5%;
  }
`
export const LowerCase = styled.span`
text-transform: lowercase;
`
export const GameLines = styled.div`
position: absolute;
bottom: 5%;
right: 10%;
width: 100px;

div:nth-child(1) {
  width: 90px;
  border-bottom: 1px solid red;
  position: absolute;
  bottom: 10%;
  right: -12px;
}

div:nth-child(2) {
  width: 63px;
  border-bottom: 1px solid red;
  position: absolute;
  bottom: 13px;
  right: 0;
}

div.red {
  border-bottom: 2px solid red;
}

div.yellow {
  border-bottom: 2px solid yellow;
}
`

export const ButtonSpace = styled.div`
text-align: center;
width: 303px;
position: relative;
margin: auto;
`
export const Btn = styled.button`
position: relative;
padding: 25px 0;
background-color: #2B59FF;
border-radius: 22px;
color: white;
text-transform: uppercase;
font-weight: 600;
font-size: 22px;
margin: 50px 0;
outline: none;
border: none;
text-align: center;
width: 303px;
z-index: 2;

@media screen and (max-width: 992px) { 
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 16px;
  width: auto;
  }
`

export const BtnShadow = styled.img`
position: absolute;
width: 100%;
right: 0;
top: 20%;
z-index: 1;

@media screen and (max-width: 992px) { 
  width: 55%;
  height: 84%;
  right: 23%;
  top: 37%;
  }
`

export const CardOfGame = styled.div`
position: relative;
`

export const RectangleY = styled.div`
width: 20px;
height: 20px;
background-color: #FFFF0A;
`

export const RectangleR = styled.div`
width: 20px;
height: 20px;
background-color: #ED0A07;
`

export const Line = styled.div`
width: 2px;
background:linear-gradient(#C552FF,#2200A3);
position: relative;
height: 80%;

@media screen and (min-width: 992px) { 
  height: 100%;
}

@media screen and (max-width: 992px) { 
  display: none;
  }
`

export const Rockit = styled.img`
position: absolute;
max-width: 370px;

@media screen and (max-width: 992px) { 
  max-width: 330px;
  }

@media screen and (min-width: 992px) { 
  max-width: 265px;
}

// @media screen and (min-width: 1200px) { 
//   max-width: 320px;
// }
`
export const EllipseTop = styled.img`
position: absolute;
left: 50px;

@media screen and (max-width: 992px) { 
   display: none;
}

@media screen and (min-width: 992px) { 
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`

export const NewsSection = styled.div`
padding: 0 10%;

@media screen and (max-width: 992px) { 
  padding: 0 5%;
  }

@media screen and (min-width: 992px) { 
  padding-top: 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}
`
export const News = styled.div`
display: flex;
gap: 40px;
justify-content: space-evenly;
`

export const Card = styled.div`
position: relative;
`

export const Image = styled.img`
background: linear-gradient(179.93deg, rgba(0, 0, 0, 0) 0.06%, rgba(0, 0, 0, 0.2) 99.94%), #0F172A;
border-radius: 22px;
padding: 20% 30%;
width: 100%;

@media screen and (max-width: 992px) { 
  padding: 15% 30%;
  }
`
export const Name = styled.h1`
font-weight: 600;
font-size: 22px;
height: auto;
max-width: 250px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical; 
`
export const InfoSection = styled.div`
width: 100%;
`

export const Arrows = styled.div`
text-align: right;
display: flex;
gap: 20px;
justify-content: flex-end;
margin-bottom: 46px;
// margin-top: 25px;
height: 38px;


@media screen and (max-width: 992px) { 
  margin:0 0 38px 0;
  gap: 12px;
  }
`
export const LeftArrow = styled.img`
background: #F8F7F7;
box-shadow: 0px 3.63641px 3.63641px rgba(0, 0, 0, 0.25);
border-radius: 50%;
padding: 12px;
@media screen and (max-width: 992px) { 
  display: none;
  }
`

export const RightArrow = styled.img`
background: #F8F7F7;
box-shadow: 0px 3.63641px 3.63641px rgba(0, 0, 0, 0.25);
transform: matrix(-1, 0, 0, 1, 0, 0);
border-radius: 50%;
padding: 12px;

@media screen and (max-width: 992px) { 
  padding: 10px;
  }
`

export const ArrowSpace = styled.div`
cursor: pointer;
`
export const NewsTitle = styled.div`
align-items: center;
display: flex;
height: 50px;

@media screen and (max-width: 992px) { 
  grid-template-columns: 100%;
  }
`

export const Info = styled.p`
font-size: 14px;
line-height: 14px;
color: #999999;
margin-top: 5px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
`

export const ReadMoreNews = styled.a`

`

export const NewsLink = styled(Link)`
text-decoration: none;
color: black;
`
export const PartnersSection = styled.div`
background-color:#0F172A; 
padding-top: 52px;

@media screen and (max-width: 992px) { 
  padding: 10% 5% 5% 5%;
  }

  @media screen and (min-width: 992px) { 
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const Partners = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
overflow: hidden;
gap: 0;
`

export const PartnersSlider = styled.div`
text-align: center;
margin-top: 38px;
margin-bottom: 59px;

@media screen and (max-width: 992px) { 
 margin-top: 40px;
 margin-bottom: 20px;
  }
`
export const PartnersArrows = styled.div`
display: none;

@media screen and (max-width: 992px) { 
  padding-bottom:24px;
  color: white;
  text-align: right;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  }
`
export const CertificatesSection = styled.div`
background: linear-gradient(358.57deg, #F7F7F7 -17%, rgba(255, 255, 255, 0) 240.33%);
position: relative;

@media screen and (min-width: 992px) { 
  @media screen and (min-width: 992px) { 
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
`

export const DotsDesignCertificates = styled.div`
img:nth-child(1) {
  position: absolute;
  right: 0;
  top: 50%;
  height: 86.4px;
  opacity: 0.39;

  @media screen and (max-width: 992px) { 
    display: none;
    }
}

img:nth-child(2) {
  position: absolute;
  left: -5%;
  top: 10%;
  height: 126px;
  opacity: 0.39;

  @media screen and (max-width: 992px) { 
    display: none;
    }
}
`



