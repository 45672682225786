import React from 'react'
import { useForm } from "react-hook-form";
import rockitMan from "../../assets/images/rockit-man.svg"
import circles from "../../assets/images/circles.svg"
import StyledButton from "../../components/Button/Button"
import buttonshadow from "../../assets/images/btnshadow.png"
import { Link } from "react-router-dom";
import { Wrapper, ContactSection, Heading, ContactSectionContent, ContactButtonSpace, ContactButton, ButtonShadow, FormSection, IconSection, IconCircles, Form, FormHeader, Title, ContactBtn, Icon, CirclesContact, InputDiv, Input, ErrorMessage, SocialMedia, SocialMediaLink, LinkedinIcon, FacebookIcon, MapSection } from "./ContactStyles"

function Contact() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data: any) => console.log(data);

    return (
        <Wrapper>
            <ContactSection>
                <Heading>get in touch</Heading>
                <ContactSectionContent>
                    <FormSection>
                        <FormHeader>
                            <Title>Say Hello!</Title>
                            <ContactBtn>Contact</ContactBtn>
                        </FormHeader>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <InputDiv>
                                <Input placeholder='Name' {...register("Name", { required: true })} />
                                {errors.Name && errors.Name.type === "required" && (
                                    <ErrorMessage>This field is required</ErrorMessage>
                                )}
                            </InputDiv>
                            <InputDiv>
                                <Input placeholder='Email' {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format"
                                    }
                                })}
                                    type="email" />
                                {errors.email && (
                                    <ErrorMessage>This field is required</ErrorMessage>
                                )}
                            </InputDiv>
                            <InputDiv>
                                <Input placeholder='Message' {...register("Message", { required: true })} />
                                {errors.Message && errors.Message.type === "required" && (
                                    <ErrorMessage>This field is required</ErrorMessage>
                                )}
                            </InputDiv>
                            <ContactButtonSpace>
                                <ContactButton>
                                    <StyledButton text="CONTACT US" c_height={"68px"} c_width={"100%"}></StyledButton>
                                    <ButtonShadow src={buttonshadow} alt="" />
                                </ContactButton>
                                <div>
                                    <SocialMedia>
                                        <SocialMediaLink href='https://www.linkedin.com/company/playtron/'>
                                            <LinkedinIcon />
                                        </SocialMediaLink>

                                        <Link to="">
                                            <FacebookIcon />
                                        </Link>
                                    </SocialMedia></div>
                            </ContactButtonSpace>
                        </Form>
                    </FormSection>
                    <IconSection>
                        <IconCircles>
                            <Icon src={rockitMan} alt="" />
                            <CirclesContact src={circles} alt="" />
                        </IconCircles>
                    </IconSection>
                </ContactSectionContent>
            </ContactSection>
            <MapSection>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2028.5105581575226!2d24.805504!3d59.4412372!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692934ca58b4eab%3A0x16cb399b3afc0e38!2sValge%2013%2C%2010127%20Tallinn%2C%20Estonia!5e0!3m2!1sen!2sge!4v1670850216377!5m2!1sen!2sge" width="100%" height="409" style={{ border: 0 }}></iframe>
            </MapSection>
        </Wrapper>
    )
}

export default Contact