import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
@media screen and (max-width: 576px) { 
  margin-top: 15%;
}

@media (min-width: 577px) and (max-width: 992px) { 
  margin-top: 8%;
}
`
export const CardLink = styled(Link)`
text-decoration: none;
color: black;
`

export const Heading = styled.div`
background-color: #F7F7F7;
text-align: center;
padding: 54px 33% 57px 33%;
@media screen and (max-width: 992px) { 
    padding: 24px 10% 24px 10%;
    margin-bottom: 54px;
}
`

export const Header = styled.h2`
font-size: 42px;
line-height: 49px;
letter-spacing: 0.14em;
text-transform: uppercase;
margin-bottom: 28px;
@media screen and (max-width: 992px) { 
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 24px;
}
`

export const Description = styled.p`
font-size: 18px;
line-height: 21px;
text-align: center;
color: #999999;
`

export const LatestNews = styled.div`
display: flex;
justify-content: space-between;
width: 90%;
margin: auto;
display: grid;
grid-template-columns: 30% 30% 30%;
margin-top: 80px;

@media screen and (max-width: 992px) { 
    grid-template-columns: 100%;
    gap: 50px;
    margin-top: 0;
}
`

export const Card = styled.div`
transition: 0.5s;
cursor: pointer;
text-decoration: none;
max-width: 480px;
margin-bottom: 118px;

@media screen and (max-width: 992px) { 
    margin-bottom: 37px;
}

&:hover {
    transform:scale(1.1);
    z-index: 2;
  }
`
export const NewsSection = styled.div`
width: 90%;
margin: 80px auto;
flex-wrap: wrap;

@media screen and (max-width: 992px) { 
    margin-top: 24px;
}
`

export const Image = styled.img`
background: linear-gradient(179.93deg, rgba(0, 0, 0, 0) 0.06%, rgba(0, 0, 0, 0.2) 99.94%), #0F172A;
border-radius: 22px;
padding: 20% 30%;
width: 100%;
max-height: 300px;

@media screen and (max-width: 992px) { 
  padding: 15% 30%;
  }
`
export const Name = styled.h1`
font-weight: 600;
font-size: 22px;
height: auto;
max-width: 250px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical; 
`
export const InfoSection = styled.div`
width: 100%;
`

export const NewsTitle = styled.div`
align-items: center;
display: flex;
height: 50px;
text-decoration: none;

@media screen and (max-width: 992px) { 
  grid-template-columns: 100%;
  }
`

export const Info = styled.p`
font-size: 14px;
line-height: 14px;
color: #999999;
margin-top: 5px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
text-decoration: none;
`
