import { ContactSection, ContactButtonSpace, DotsDesign, Circles, IconSection, IconCircles, Icon, ContactSectionContent, BtnShadow } from "../../features/Games/GamesStyles"
import rockitMan from "../../assets/images/rockit-man.svg"
import dots from "../../assets/images/dots.png"
import StyledButton from "../../components/Button/Button"
import buttonshadow from "../../assets/images/btnshadow.png"
import circles from "../../assets/images/circles.svg"
import { Link } from "react-router-dom"
import styled from 'styled-components'

function ContactUs() {
    return (
        <ContactSection>
            <DotsDesign>
                <img src={dots} alt="" />
                <img src={dots} alt="" />
                <img src={dots} alt="" />
            </DotsDesign>
            <ContactSectionContent>
                <IconSection>
                    <IconCircles>
                        <Icon src={rockitMan} alt="" />
                        <Circles src={circles} alt="" />
                        <Links to={"/contact"}>
                            <ContactButtonSpace>
                                <StyledButton text="CONTACT US" c_height={"68px"} c_width={"100%"}></StyledButton>
                                <BtnShadow src={buttonshadow} alt="" />
                            </ContactButtonSpace>
                        </Links>
                    </IconCircles>
                </IconSection>
            </ContactSectionContent>
        </ContactSection>
    )
}

const Links = styled(Link)`
position: absolute;
bottom: 10%;
right: 20%;

@media screen and (max-width: 992px) { 
    right: 5%;
}
`

export default ContactUs