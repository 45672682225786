import styled from "styled-components";

export const Wrapper = styled.div`
// width: 90%;
// margin: 50px auto;
padding: 50px 10%;
line-height: 1.5;
background-color: #f6f6f6;
`

export const Header = styled.h1`

`

export const Title = styled.h2`

`

export const Text = styled.p`
color: #333;
`

export const List = styled.ul`
padding: 0 20px;
`
export const ListItem = styled.li`
list-style-type: disc;
list-style: inside;
word-break: break-all;
`