import React from 'react'
import { Wrapper, AboutUsSection, AboutUsInfo, Title, Description, AboutUsImage, ImageSpace, InfoImage, TimelineSection, Heading, Timeline, LineSpace, Line, TimelineFirst, TimelineSecond, Rectangles, Rectangle, MobileLine } from "./AboutUsStyles"
import TimelineCard from '../../components/TimelineCard/TimelineCard'
import ContactUs from '../../components/ContactUs/ContactUs'

function AboutUs() {
    return (
        <div>
            <Wrapper>
                <AboutUsSection id='integrationprocess'>
                    <AboutUsInfo>
                        <Title>About Us</Title>
                        <Description>
                            Rockit is a new kind of social multiplayer game consisting of an increasing curve that can crash anytime. When round starts, a scale of multiplier starts growing. Player must cash out before lucky plane flies away. <br></br><br></br>
                            Originated from video gaming industry, this format became popular in crypto casinos, due to its simplicity and exciting gambling experience. <br></br><br></br>

                            We are amongst pioneers to introduce this game to regular online casinos.
                            Rockit is a new kind of social multiplayer game consisting of an increasing curve that can crash anytime. When round starts, a scale of multiplier starts growing. Player must cash out before lucky plane flies away. <br></br><br></br>
                            Originated from video gaming industry, this format became popular in crypto casinos, due to its simplicity and exciting gambling experience. <br></br><br></br>

                            We are amongst pioneers to introduce this game to regular online casinos.
                        </Description>
                    </AboutUsInfo>
                    <AboutUsImage>
                        {/* <InfoImage src={RockitGame} alt="" /> */}
                        <ImageSpace></ImageSpace>
                    </AboutUsImage>
                </AboutUsSection>
                <TimelineSection id='walletintegration'>
                    <Heading>
                        <Title>Who we are</Title>
                        <Description>
                            Lots of people play our games daily on different devices. We are proud to be presented in the entertainment industry because we think that playing games is the core of human being.
                        </Description>
                    </Heading>
                    <Timeline>
                        <TimelineFirst>
                            <TimelineCard title='The begining' description='We’ve started with a small office and only 3 employees'></TimelineCard>
                            <MobileLine></MobileLine>
                            <TimelineCard title='rockit!' description='We’ve started with a small office and only 3 employees'></TimelineCard>
                            <MobileLine></MobileLine>
                            <TimelineCard title='B-88' description='We’ve started with a small office and only 3 employees'></TimelineCard>
                            <MobileLine></MobileLine>
                        </TimelineFirst>
                        <LineSpace>
                            <Line></Line>
                            <Rectangles>
                                <Rectangle></Rectangle>
                                <Rectangle></Rectangle>
                                <Rectangle></Rectangle>
                                <Rectangle></Rectangle>
                                <Rectangle></Rectangle>
                            </Rectangles>
                        </LineSpace>
                        <TimelineSecond>
                            <TimelineCard title='Contrast with Europabet' description='We’ve started with a small office and only 3 employees'></TimelineCard>
                            <MobileLine style={{ marginTop: "35px" }}></MobileLine>
                            <TimelineCard title='Contract with parimatch' description='We’ve started with a small office and only 3 employees'></TimelineCard>
                        </TimelineSecond>
                    </Timeline>
                </TimelineSection>
                <ContactUs></ContactUs>
            </Wrapper>
        </div>
    )
}
export default AboutUs