import news from "../../NewsData.json"
import { Wrapper, Heading, Header, Description, NewsSection, LatestNews, Image, Name, InfoSection, NewsTitle, Card, CardLink, Info, } from "./NewsStyles"

function News() {
    return (
        <Wrapper>
            <Heading>
                <Header>company NEWS</Header>
                <Description> Rockit is a new kind of social multiplayer game consisting of an increasing curve that can crash anytime. When round starts, a scale of multiplier starts growing. Player must cash out before lucky plane flies away.</Description>
            </Heading>
            <LatestNews>
                {
                    news.map((data, index) => {
                        return (
                            <Card key={index}>
                                <CardLink to={`/news/${data.title}`}>
                                    <div>
                                        <Image src={`/${data.img}`} alt="" />
                                    </div>
                                    <InfoSection>
                                        <NewsTitle>
                                            <Name>{data.title}</Name>
                                        </NewsTitle>
                                        <Info>{data.description}</Info>
                                    </InfoSection>
                                </CardLink>
                            </Card>
                        );
                    })
                }
            </LatestNews>
        </Wrapper>
    )
}

export default News