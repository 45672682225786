import styled from "styled-components";

const BurgerContainer = styled.div`
  cursor: pointer;

  div {
    width: 21px;
    height: 2.33px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 50px;
    margin-bottom: 4.67px;
  }

  div:nth-child(1) {
    width: 9px;
  }

  div:nth-child(3) {
    width: 9px;
    margin-bottom: 0;
    float: right;
  }

  &.open {
    div:nth-child(1) {
      width: 21px;
      transform: rotate(-45deg) translate(-5px, 4px);
    }

    div:nth-child(2) {
      opacity: 0;
    }

    div:nth-child(3) {
      width: 21px;
      transform: rotate(45deg) translate(-6px, -6px);
    }
  }

  @media (min-width: ${1000}px) {
    display: none;
  }
`;

function Burger({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) {
  return (
    <BurgerContainer className={isOpen ? "open" : ""} onMouseDown={onClick}>
      <div />
      <div />
      <div />
    </BurgerContainer>
  );
}

export default Burger;
