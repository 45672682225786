import React, { FC } from 'react'
import { DetailsWrapper, Title, Description, Icon, ReadMore, IconShadow, IconSpace } from '../../features/Home/HomeStyles'
import Shadow from "../../assets/images/shadow.png"
interface AboutUsDetail {
    img: string;
    title: string;
    description?: string;
    readMore?: string;
}
const AboutUsDetail: FC<AboutUsDetail> = ({ img, title, description, readMore }) => (
    <DetailsWrapper>
        <IconSpace>
            <Icon src={img} alt="" />
            <IconShadow src={Shadow} alt="" />
        </IconSpace>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ReadMore to={"/aboutus"}>{readMore}</ReadMore>
    </DetailsWrapper>
)

export default AboutUsDetail