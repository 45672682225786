import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { variables } from "../../constants/variables";

const StyledButton = styled.button<{
  height?: string;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  background: string;
  blocked?: boolean;
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  outline: 0;
  font-weight: 600;
  border: none;
  border-radius: 22px;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  background: ${(props) => props.background};
  opacity: ${({ blocked }) => (blocked ? 0.5 : 1)};

  &:disabled {
    background: ${(props) => props.theme.colors.transparent3};
  }

  @media screen and (max-width: 992px) { 
    font-size: 16px;
    line-height: 19px;
    width: 167px;
    height: 42px;
    border-radius: 12px;
    margin-top: 0;
    margin-bottom: 0;
}
`;

export const ButtonNumericLabel = styled.span`
  // font-family: "Roboto", monospace;
`;

const Button: FC<{
  text?: string | ReactNode;
  c_height?: string;
  c_width?: string;
  marginTop?: string;
  marginBottom?: string;
  onClick?(): void;
  blockedAction?(): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  blocked?: boolean;
}> = ({
  text,
  c_height,
  c_width,
  marginTop,
  marginBottom,
  onClick,
  blockedAction,
  className,
  background,
  disabled,
  blocked,
}) => {
    return (
      <StyledButton
        className={className}
        height={c_height}
        width={c_width}
        marginTop={marginTop}
        marginBottom={marginBottom}
        onClick={blocked ? blockedAction : onClick}
        background={background ? background : variables.colors.main}
        disabled={disabled}
        blocked={blocked}
      >
        {text}
      </StyledButton>
    );
  };

export default Button;
