import styled from "styled-components";

export const Wrapper = styled.div`
background: linear-gradient(180deg, #F7F7F7 34.51%, #FFFFFF 71.27%);
@media screen and (max-width: 576px) { 
    margin-top: 15%;
}

@media (min-width: 577px) and (max-width: 992px) { 
    margin-top: 8%;
}
`
export const ButtonSpace = styled.div`
text-align: center;
width: 303px;
position: relative;
margin: auto;
`
export const ContactButtonSpace = styled.div`
text-align: center;
width: 274px;
position: relative;
margin: auto;
`
export const BtnShadow = styled.img`
position: absolute;
width: 100%;
right: 0;
top: -4px;
z-index: 1;

@media screen and (max-width: 992px) { 
  width: 60%;
  height: 82%;
  right: 21%;
  top: 37%;
  }
`
export const SectionBackground = styled.div`
background: linear-gradient(180deg, #0D070F 0%, #1F102B 100%);
width: 100%;
height: 681px;
margin-bottom: 62px;

@media screen and (max-width: 992px) { 
    margin-bottom: 29px;
    }
`

export const GameSection = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 90%;
margin: auto;
margin-top: 63px;

@media screen and (max-width: 992px) { 
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-top: 27px;
    }
`

export const GameSectionInfo = styled.div`
flex: 1;
`

export const Title = styled.h2`
font-size: 54px;
line-height: 63px;
letter-spacing: 0.14em;
text-transform: uppercase;

@media screen and (max-width: 992px) { 
    font-size: 22px;
    line-height: 26px;
}
`

export const Description = styled.p`
font-size: 18px;
line-height: 21px;
color: #999999;

@media screen and (max-width: 992px) { 
    font-size: 12px;
}
`

export const GameImage = styled.div`
display: flex;
justify-content: flex-end;
flex: 1.5;

@media screen and (max-width: 992px) { 
    justify-content: center;
}
`

export const InfoImage = styled.img`
border-radius: 22px;
width: 544px;
height: 255px;

@media screen and (max-width: 992px) { 
    width: 343px;
    height: 169px;
    }
`

export const Details = styled.div`
 display: flex;
 gap: 120px;
 justify-content: space-evenly;
 text-align: center;

 @media screen and (max-width: 992px) { 
  display: flex;
  flex-direction: column;
  gap: 50px;
  }
`

export const Section = styled.div`
width: 90%;
margin: auto;
// margin-top: 123px;

@media screen and (max-width: 992px) { 
    margin-top: 54px;
    }
`
export const DevicesSection = styled.div`
display: flex;
width: 90%;
justify-content: center;
position: relative;
overflow: hidden;

@media screen and (max-width: 992px) { 
    margin-bottom: 20px;
    }
`

export const DevicesSectionDots = styled.div`

img:nth-child(1) {
    position: absolute;
    width: 159px;
    right: -240px;
    top: 150px;

    @media screen and (max-width: 992px) { 
        display: none;
    }
  }
  
img:nth-child(2) {
    width: 120.8px;
    position: absolute;
    transform: rotate(90deg);
    right: 90px;
    bottom: 65px;
    z-index: 1;

    @media screen and (max-width: 992px) { 
        display: none;
    }
}
  
img:nth-child(3) {
    position: absolute;
    width: 159px;
    left: 0;
    bottom: 27%;

    @media screen and (max-width: 992px) { 
        display: none;
    }
}
`

export const Image = styled.img`
width: 90%;
z-index: 2;
`

export const FreeBetsSection = styled.div`
width: 90%;
margin: auto;
position: relative;
margin-top: 126px;

@media screen and (max-width: 992px) { 
    margin-top: 52px;
    text-align: center;
    }
`


export const FreeBetsSectionTitle = styled.div`
font-size: 42px;
line-height: 49px;
letter-spacing: 0.14em;
text-transform: uppercase;

@media screen and (max-width: 992px) { 
    font-size: 24px;
    line-height: 28px;
}
`


export const FreeBets = styled.div`
background: #FFFFFF;
box-shadow: 0px 11px 12px 3px rgba(0, 0, 0, 0.04);
border-radius: 22px;
width: 589px;
height: auto;
margin-left: -150px;
margin-top: 237px;
text-align: center;
padding: 0 80px;

@media screen and (max-width: 992px) { 
    margin-top: 0;
    margin-left: 0;
    order: 1;
    width: 340px;
    height: auto;
    padding: 0 10px;
    }
`
export const FreeBetsTitle = styled.div`
font-size: 22px;
line-height: 26px;
letter-spacing: 0.14em;
font-weight: bold;
text-transform: uppercase;
margin-top: 46px;
margin-bottom: 44px;

@media screen and (max-width: 992px) { 
    margin-top: 26px;
    order: 1;
    margin-bottom: 22px;
    font-size: 12.3px;
    line-height: 15px;
    }
`

export const FreeBetsDescription = styled.p`
font-size: 22px;
line-height: 26px;
text-align: center;
color: #999999;
margin-bottom: 40px;
@media screen and (max-width: 992px) { 
    font-size: 13px;
    line-height: 15px;
    }
`
export const FreebetsImage = styled.img`
max-width: 841px;
height: 341.77px;

@media screen and (max-width: 992px) { 
    width: 340px;
    height: 138px;
    order: 2;
    }
`

export const FreebetsContent = styled.div`
display: flex;
margin: auto;
justify-content: center;
margin-top: 62px;
margin-bottom: 122px;

@media screen and (max-width: 992px) { 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
    }
`
export const Card = styled.div`
`

export const GameDetailsSection = styled.div`
display: flex;
width: 80%;
justify-content: space-between;
margin: auto;
gap: 20px;

@media screen and (max-width: 992px) { 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 62px;
    margin: auto;
    }
`
export const GameCardDetail = styled.div`
border: 1px solid rgba(0, 0, 0, 0.1);
position:relative; 
max-width: 340px;
height: 200px;
border-radius: 2px;
padding: 40px 20px;
text-align: center;
 
&:after {
    content: "";
    position:absolute;
    margin:-20px;
    width:40px;
    bottom: -4px;
    right: 6px;
    height:60px;
    transform:rotate(45deg);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    z-index: 3;
  }
}

@media screen and (max-width: 992px) { 
    margin-left: 0;
    with: 100%;
    height: auto;
    }
`

export const GameCardDetailTitle = styled.h1`
font-weight: 400;
font-size: 28px;

@media screen and (max-width: 992px) { 
    font-size: 16px;
    }
`

export const GameCardDetailDescription = styled.p`
font-weight: 400;
font-size: 14px;
line-height: 16px;
text-align: center;
color: #999999;
`

export const GameIconSpace = styled.div`
position: absolute;
top: -35px;
overflow: hidden;
display: flex;
justify-content: center;
border-radius: 2px;

&:after {
    content: "";
    position:absolute;
    margin:-20px;
    width:35px;
    bottom: 0;
    right: -2px;
    height:35px;
    transform:rotate(45deg);
    background-color: #FFFFFF;
    z-index: 3;
  }
  
  div.blue {
    width: 66px;
    height: 70px;
    background-color: #E1E8FD;
  }

  div.red {
    width: 66px;
    height: 70px;
    background-color: #FFE0E0;
  }

  
  div.green {
    width: 66px;
    height: 70px;
    background-color: #DBFFCC;
  }
`

export const GameIcon = styled.img`
padding-top: 15px;
`
export const ContactSectionContent = styled.div`
margin-top: 70px;
text-align: center;

@media screen and (max-width: 992px) { 
    margin-top: 50px;
}
`
export const ContactSection = styled.div`
display: flex;
justify-content: center;
margin-top: 105px;
position: relative;
overflow-x: hidden;
padding-bottom: 112px;

@media screen and (max-width: 992px) { 
    margin-top: 54px;
    margin-bottom: 0;
    padding-bottom: 54px;
}
`

export const RockitMan = styled.img`
@media screen and (max-width: 992px) { 
    width: 230px;
}
`

export const DotsDesign = styled.div`
img:nth-child(1) {
    position: absolute;
    width: 225px;
    right: -70px;
    top: 0;
    transform: rotate(90deg);

    @media screen and (max-width: 992px) { 
        display: none;
    }
  }
  
img:nth-child(2) {
    width: 130px;width: 130px;
    position: absolute;
    transform: rotate(90deg);
    right: 250px;
    bottom: 50px;

    @media screen and (max-width: 992px) { 
        display: none;
    }
}
  
img:nth-child(3) {
    position: absolute;
    width: 220px;
    left: 0;
    bottom: 40%;

    @media screen and (max-width: 992px) { 
        width: 100px;
        top: 5px;
        left: -20px;
    }
}
`
export const IconSection = styled.div`
display: flex;
justify-content: flex-end;
max-height: 496px;

@media screen and (max-width: 992px) { 
   max-height: 313px;
   max-width: 313px;
   display: flex;
   justify-content: center;
   margin: auto;
   margin-bottom: 54px;
}
`

export const IconCircles = styled.div`
position: relative;
width: 496px;
`
export const Icon = styled.img`
top: 18%;
right: 15%;
position: absolute;
width: 364px;

@media screen and (max-width: 992px) { 
    width: 230px;
    top: 18%;
    right: 15%;
}
`
export const Circles = styled.img`
@media screen and (max-width: 992px) { 
    width: 313px;
}`