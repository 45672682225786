import styled from "styled-components";
import Linkedin from "../../components/Icons/Linkedin";
import Facebook from "../../components/Icons/Facebook";

export const Wrapper = styled.div`
background-color: #F7F7F7;
@media screen and (max-width: 576px) { 
    margin-top: 15%;
}

@media (min-width: 577px) and (max-width: 992px) { 
    margin-top: 8%;
}
`

export const ContactSection = styled.div`
display: flex;
flex-direction: column;
width: 90%;
margin: 0 auto;
margin-top: 54px;

@media screen and (max-width: 992px) { 
    margin-top: 24px;
}
`

export const Heading = styled.h2`
font-size: 42px;
line-height: 49px;
letter-spacing: 0.14em;
text-transform: uppercase;
color: #000000;
text-align: center;

@media screen and (max-width: 992px) { 
    font-size: 22px;
    line-height: 26px;
}`

export const ContactSectionContent = styled.div`
margin-top: 74px;
display: grid;
grid-template-columns: 50% 50%;

@media screen and (max-width: 992px) {
    display: flex; 
    flex-direction: column;
    margin-top: 24px;
}

@media screen and (max-width: 1280px) {
    gap: 50px;
}
`
export const FormSection = styled.div`

`
export const Form = styled.form`
display: flex;
flex-direction: column;
`

export const Input = styled.input`
margin-bottom: 5px;
outline: 0;
width: 100%;
padding-bottom: 16px;
border: none;
background-color: #F7F7F7;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);

::placeholder {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
}

@media screen and (max-width: 992px) {
    margin-bottom: 2px;
}

@media screen and (max-width: 1280px) {
    padding-bottom: 20px;
}
`
export const InputDiv = styled.div`
margin-bottom: 63px;

&:nth-child(3) {
    margin-bottom: 42px;

    @media screen and (max-width: 1280px) {
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 992px) { 
    margin-bottom: 30px;
}
`

export const ErrorMessage = styled.span`
font-style: italic;
font-size: 14px;
color: #999999;
`
export const FormHeader = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 42px;
gap: 46px;

@media screen and (max-width: 992px) { 
    flex-direction: row;
    justify-content: space-between;
}
`

export const ContactBtn = styled.button`
border: 1px solid #2B59FF;
border-radius: 12px;
font-size: 16px;
line-height: 19px;
padding: 4px 2px;
width: 87px;
`

export const Title = styled.h3`
font-size: 28px;
line-height: 33px;
letter-spacing: 0.14em;

@media screen and (max-width: 992px) { 
    font-size: 18px;
    line-height: 21px;
}
`

export const IconSection = styled.div`
display: flex;
justify-content: flex-end;
max-height: 496px;

@media screen and (max-width: 992px) { 
   max-height: 313px;
   max-width: 313px;
   display: flex;
   justify-content: center;
   margin: auto;
   margin-bottom: 54px;
}
`

export const IconCircles = styled.div`
position: relative;
width: 496px;
`
export const Icon = styled.img`
top: 18%;
right: 15%;
position: absolute;
width: 364px;

@media screen and (max-width: 992px) { 
    width: 230px;
    top: 18%;
    right: 15%;
}
`
export const Circles = styled.img`
position: absolute;
@media screen and (max-width: 992px) { 
    width: 313px;
}
`

export const CirclesContact = styled.img`
@media screen and (max-width: 992px) { 
    width: 313px;
}
`

export const ContactButtonSpace = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

@media screen and (max-width: 992px) { 
    margin-top: 0;
}
`

export const ContactButton = styled.div`
width: 274px;
position: relative;
`
export const ButtonShadow = styled.img`
position: absolute;
width: 100%;
right: 0;
top: -4px;
z-index: 1;

@media screen and (max-width: 992px) { 
  width: 167px;
  height: 90%;
  right: 39%;
  top: 33%;
  }
`

export const SocialMedia = styled.div`
  display: flex;

  a:nth-child(2) {
    margin-left: 27px;

    @media screen and (max-width: 992px) { 
        margin-left: 14px;
    }
  }

  @media (min-width: ${1000}px) {
    order: 3;
    margin-bottom: 0;
  }
`;

export const SocialMediaLink = styled.a`

`
export const MapSection = styled.div`
width: 90%;
margin: auto;
margin-bottom: 96px;
margin-top: 108px;

@media screen and (max-width: 992px) { 
    width: 100%;
    margin: auto;
    margin-bottom: 54px;
}
`

export const LinkedinIcon = styled(Linkedin)`
width: 46px;

@media screen and (max-width: 992px) { 
    width: 22px;
    }
`

export const FacebookIcon = styled(Facebook)`
width: 46px;

@media screen and (max-width: 992px) { 
    width: 22px;
    }
`

