import styled from "styled-components";

export const Wrapper = styled.div`
background-color: #F7F7F7;

@media screen and (max-width: 576px) { 
    margin-top: 15%;
}

@media (min-width: 577px) and (max-width: 992px) { 
    margin-top: 8%;
}
`

export const AboutUsSection = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 90%;
margin: auto;
padding-top: 54px;
display: grid;
grid-template-columns: 40% 40%;

@media screen and (max-width: 992px) { 
    grid-template-columns: 100%;
    padding-top: 24px;

    br {
        display: none;
    }
}
`
export const AboutUsInfo = styled.div`
flex: 1;

@media screen and (max-width: 992px) { 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    margin-bottom: 28px;
    } 
`

export const Title = styled.h2`
font-size: 42px;
line-height: 49px;
letter-spacing: 0.14em;
text-transform: uppercase;
color: #000000;

@media screen and (max-width: 992px) { 
    font-size: 22px;
    line-height: 26px;
}
`

export const Description = styled.p`
font-size: 18px;
line-height: 21px;
color: #999999;

@media screen and (max-width: 992px) { 
    font-size: 12px;
    margin-top: 8px;
}
`

export const AboutUsImage = styled.div`
display: flex;
justify-content: flex-end;
flex: 1.5;

@media screen and (max-width: 992px) { 
    justify-content: center;
}
`
export const ImageSpace = styled.div`
background-color: #D9D9D9;
width: 100%;
height: 409px;
margin-top: 10px;
`
export const InfoImage = styled.img`
`

export const TimelineSection = styled.div`
text-align: center;
margin-top: 186px;
margin-bottom: 17px;
@media screen and (max-width: 992px) {
    margin-top: 54px;
    }
`

export const Heading = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
width: 55%;
margin: auto;

@media screen and (max-width: 992px) {
    width: 70%; 
    gap: 0;
    }
`

export const Timeline = styled.div`
display: flex;
width: 80%;
margin: auto;
justify-content: space-around;
margin-top: 94px;
height: 1262px;
gap: 50px;

@media screen and (max-width: 992px) { 
    flex-direction: column;
    width: 90%;
    align-items: center;
    margin-top: 0;
    margin-bottom: 95px;
    gap: 0;
    }
`

export const Rectangles = styled.div`
div:nth-child(1) {
position: absolute;
top: 210px;
right: -9px;
}

div:nth-child(2) {
position: absolute;
top: 420px;
right: -9px;
}

div:nth-child(3) {
position: absolute;
top: 630px;
right: -9px;
}

div:nth-child(4) {
position: absolute;
top: 840px;
right: -9px;
}

div:nth-child(5) {
position: absolute;
top: 1050px;
right: -9px;
}
`

export const Rectangle = styled.div`
width: 20px;
height: 20px;
background-color:  #230F6E;
`

export const TimelineFirst = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
padding-top: 26px;

@media screen and (max-width: 992px) { 
    align-items: center;
    margin-top: 54px;
}
`

export const TimelineSecond = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
padding: 110px 0 90px 0;

@media screen and (max-width: 992px) { 
    padding: 0;
    align-items: center;
    justify-content: space-around;
}
`

export const CardWrapper = styled.div`
max-width: 514px;
height: 200px;
overflow: hidden;

@media screen and (max-width: 992px) { 
    max-width: 340px;
}
`
export const Card = styled.div`
border: 2px solid rgba(0, 0, 0, 0.1);
border-radius: 3px;
position:relative; 
width: 100%;
height: 100%;
border-radius: 2px;
// padding: 2rem 6rem;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
 
&:after {
    content: "";
    position:absolute;
    margin:-20px;
    width:40px;
    bottom: -20px;
    right: 20px;
    height: 69px;
    transform:rotate(65deg);
    border-left: 1.5px solid rgba(0, 0, 0, 0.1);
    background-color: #F7F7F7;
    z-index: 3;

    @media screen and (max-width: 992px) { 
        border-left: 2px solid rgba(0, 0, 0, 0.1);
        bottom: -19px;
        right: 19px;
        }
  }
}

@media screen and (max-width: 992px) { 
    margin-left: 0;
    with: 100%;
    height: auto;
    padding: 2rem;
    }
`

export const CardTitle = styled.h2`
font-size: 22px;
line-height: 26px;
text-align: center;
letter-spacing: 0.14em;
text-transform: uppercase;

@media screen and (max-width: 992px) { 
    font-size: 18px;
    line-height: 21px;
    }
`

export const CardDescription = styled.p`
font-size: 14px;
line-height: 16px;
text-align: center;
padding: 0 6rem;

@media screen and (max-width: 992px) { 
    padding: 0;
}
`

export const LineSpace = styled.div`
position: relative;

@media screen and (max-width: 992px) { 
    display: none;
    }
`

export const MobileLine = styled.div`
display: none;

@media screen and (max-width: 992px) { 
    display: block;
    width: 84px;
    height: 1px;
    background-color: #230F6E;
    transform: rotate(90deg);
    margin: auto;
    margin-top: 16px;
    margin-bottom: 56px;
    }
`

export const Line = styled.div`
width: 2px;
background-color: #2B59FF;
height: 100%;
`