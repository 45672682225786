import news from "../../../NewsData.json"
import { useParams } from 'react-router-dom'
import { Wrapper, Container, Title, Image, Description } from "./NewsFullCardStyles"

function NewsFullCard() {
    const { title } = useParams();

    return (
        <Container>
            {news.filter(card => card.title === title).map((card, index) => (
                <Wrapper key={index}>
                    <Image src={`/${card.img}`} alt="" />
                    <Title>{card.title}</Title>
                    <Description>{card.description}</Description>
                </Wrapper>
            ))}
        </Container>
    )
}

export default NewsFullCard